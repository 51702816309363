/* globals FB, __GB_LoginModalInfo__ */
const { SiteUID, MESSENGER_PAGE_ID, RESET_MESSENGER_STATE } = typeof gbCommonInfo === 'object' ? gbCommonInfo : {}
import { getQueryString } from '@shein/common-function'

function isUseDirectOath2 () {
  // return true
  const ua = navigator.userAgent || navigator.vendor || window.opera
  // webview in fb app
  if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
    return true
  }
  return false
}

function getFBInfo (fbToken, cb) {
  FB.api('/me?fields=email', (res) => {
    const email = res && res.email || ''
    const fbid = res && res.id
    cb(fbToken, fbid, email)
  })
}

export async function callFaceBookLogin (FBMessengerConnect, cb) {
  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    window.exceptionReport = exceptionReport
  }
  const isMessenger = FBMessengerConnect == 'yes' && MESSENGER_PAGE_ID[SiteUID]
  const queryMessenger = getQueryString({ key: 'isMessenger' }) == 1
  // eslint-disable-next-line no-undef
  exceptionReport.thirdClick({ from: 'facebook' })
  const { FB_DOWNGRADE_LOGIN_METHOD } = gbCommonInfo
  if (FB_DOWNGRADE_LOGIN_METHOD == 1) {
    FB.login((res) => {
      if (!res.authResponse) return
      const fbToken = res.authResponse.accessToken
      getFBInfo(fbToken, cb)
    }, { scope: 'email' })
    return
  }
  FB.getLoginStatus((response) => {
    if (response && response.status == 'connected' && response.authResponse) {
      return getFBInfo(response.authResponse.accessToken, cb)
    }
    if (isUseDirectOath2()) {
      location.href = `${gbCommonInfo.langPath}/user/third/facebook?redirection=${location.origin}${location.pathname}`
      return
    }
    FB.login((res) => {
      if (!res.authResponse) return
      const fbToken = res.authResponse.accessToken
      getFBInfo(fbToken, cb)
    }, 
    { scope: queryMessenger || isMessenger ? 'email,user_messenger_contact' : 'email',
      messenger_page_id: queryMessenger || isMessenger ? MESSENGER_PAGE_ID[SiteUID] : '',
      reset_messenger_state: RESET_MESSENGER_STATE == 1 ? 1 : '' }
    )
  })
}

export async function callGoogleLogin (cb) {
  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    window.exceptionReport = exceptionReport
  }
  // eslint-disable-next-line no-undef
  exceptionReport.thirdClick({ from: 'google' })
  if (isUseDirectOath2()) {
    location.href = `${gbCommonInfo.langPath}/user/third/google?redirection=${location.origin}${location.pathname}`
    return
  }
  window.thirdPartLoginCb = cb
  window.open(`${location.origin}${gbCommonInfo.langPath}/user/auth/thirdMid?thirdType=google`, 'xmOpenWindow', 'width=355, height=600, menubar=no, resizeable=no, status=no, scrollbars=no') 
}

export async function callVKLogin (url, cb) {
  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    window.exceptionReport = exceptionReport
  }
  // eslint-disable-next-line no-undef
  exceptionReport.thirdClick({ from: 'vk' })
  if (isUseDirectOath2()) {
    location.href = `${gbCommonInfo.langPath}/user/third/vk?redirection=${location.origin}${location.pathname}`
    return
  }
  window.vkLoginTokenCb = cb
  const { VK_LOGIN_CLIENT_ID } = __GB_LoginModalInfo__
  window.open(`https://oauth.vk.com/authorize?client_id=${VK_LOGIN_CLIENT_ID}&is_https=1&scope=email&response_type=code&v=5.50&redirect_uri=${encodeURIComponent(url)}`, 'xmOpenWindow', 'width=655, height=349, menubar=no, resizeable=no, status=no, scrollbars=no')
}

export async function callLineLogin (cb) {
  // todo:
  // exceptionReport.thirdClick({ from: 'line' })
  // if (isUseDirectOath2()) {
  //   location.href = `${gbCommonInfo.langPath}/user/third/vk?redirection=${location.origin}${location.pathname}`
  //   return
  // }
  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    window.exceptionReport = exceptionReport
  }
  // eslint-disable-next-line no-undef
  exceptionReport.thirdClick({ from: 'line' })
  if (isUseDirectOath2()) {
    location.href = `${gbCommonInfo.langPath}/user/third/vk?redirection=${location.origin}${location.pathname}`
    return
  }
  window.thirdPartLoginCb = cb
  window.open(`${location.origin}${gbCommonInfo.langPath}/user/auth/thirdMid?thirdType=line`, 'xmOpenWindow', 'width=355, height=600, menubar=no, resizeable=no, status=no, scrollbars=no') 
}

export async function callKakaoLogin (cb) {
  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    window.exceptionReport = exceptionReport
  }
  // eslint-disable-next-line no-undef
  exceptionReport.thirdClick({ from: 'kakao' })
  if (isUseDirectOath2()) {
    location.href = `${gbCommonInfo.langPath}/user/third/vk?redirection=${location.origin}${location.pathname}`
    return
  }
  window.thirdPartLoginCb = cb
  window.open(`${location.origin}${gbCommonInfo.langPath}/user/auth/thirdMid?thirdType=kakao`, 'xmOpenWindow', 'width=355, height=600, menubar=no, resizeable=no, status=no, scrollbars=no') 
}

export async function callNaverLogin (cb) {
  if (typeof exceptionReport != 'object') {
    const exceptionReport = (await import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')).default
    window.exceptionReport = exceptionReport
  }
  // eslint-disable-next-line no-undef
  exceptionReport.thirdClick({ from: 'naver' })
  if (isUseDirectOath2()) {
    location.href = `${gbCommonInfo.langPath}/user/third/vk?redirection=${location.origin}${location.pathname}`
    return
  }
  window.thirdPartLoginCb = cb
  window.open(`${location.origin}${gbCommonInfo.langPath}/user/auth/thirdMid?thirdType=naver`, 'xmOpenWindow', 'width=355, height=600, menubar=no, resizeable=no, status=no, scrollbars=no') 
}
