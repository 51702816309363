<template>
  <svg
    t="1709707330432"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="7033"
    width="30"
    height="30"
  >
    <path
      d="M512 16c273.92 0 496 222.08 496 496 0 273.92-222.08 496-496 496-273.92 0-496-222.08-496-496C16 238.08 238.08 16 512 16z m-68.384 291.392H292.032v408.32h151.584V506.24l143.232 209.408h155.904V307.36h-150.4v212.128L443.616 307.392z"
      fill="#00CB0F"
      p-id="7034"
    />
  </svg>
</template>

<script>
export default {
  name: 'NaverIcon'
}
</script>

